import React from "react";
import { Grid, Card, CardContent, Link, Container } from "@mui/material";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ToolsPage = () => {
  const toolsQuery = useStaticQuery(graphql`
    query {
      tool1Img: file(relativePath: { regex: "/(tools/microrec)/" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            width: 150
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      tool2Img: file(relativePath: { regex: "/(tools/cameras)/" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 90
            width: 110
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      tool3Img: file(relativePath: { regex: "/(tools/optirec)/" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 70
            width: 110
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const toolImg1 = getImage(
    toolsQuery.tool1Img.childImageSharp.gatsbyImageData
  );
  const toolImg2 = getImage(
    toolsQuery.tool2Img.childImageSharp.gatsbyImageData
  );
  const toolImg3 = getImage(
    toolsQuery.tool3Img.childImageSharp.gatsbyImageData
  );

  const tools = [
    {
      title: "Smartphone based Optical Systems",
      image: toolImg1,
      link: "/microrec",
    },
    {
      title: "Optical Systems for Professional Cameras",
      image: toolImg2,
      link: "/micro-to-4k",
    },
    {
      title: "Smartphone slit lamp adapter",
      image: toolImg3,
      link: "/optirec",
    },
  ];

  return (
    <Container maxWidth={"lg"} style={{ textAlign: "center", padding: "20px" }}>
      <h1 style={{ fontSize: "40px", marginBottom: "1em" }}>
        We have the perfect digitalization
        <br /> tool for <b style={{ color: "#FFA100" }}>your practice.</b>
      </h1>
      <Grid container spacing={4} justifyContent="center">
        {tools.map((tool, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <Link href={tool.link} underline="none">
              <Card
                style={{
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "20px",
                }}
              >
                <CardContent>
                  <div style={{ fontSize: "20px", fontWeight: "700" }}>
                    {tool.title}
                  </div>
                </CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "150px",
                  }}
                >
                  <GatsbyImage
                    loading="lazy"
                    image={tool.image}
                    alt={tool.title}
                  />
                </div>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ToolsPage;
