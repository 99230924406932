import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";
import "../../static/mainPage.css";

const block = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

const text = {
  width: "40%",
  fontSize: "20px",
};

const isBrowser = typeof window !== "undefined";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

if (isBrowser) {
  window.addEventListener("scroll", reveal);
}

const Rules = () => {
  return (
    <Container maxWidth={"lg"}>
      <Grid
        rowSpacing={5}
        container
        className="container"
        marginTop={2}
        marginBottom={2}
      >
        <Grid container item style={block}>
          <Grid item xs={10} sm={5}>
            <StaticImage
              src="../../images/gifs/1.png"
              alt=""
              loading="lazy"
              placeholder="blurred"
              className="rule_img"
              quality={90}
              formats={["auto", "webp", "avif"]}
            />
          </Grid>
          <Grid item xs={10} sm={5} style={text} marginTop={{ xs: 2, sm: 0 }}>
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>
            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              Don't miss any <b style={{ color: "#FFA100" }}>interesting</b>{" "}
              case
            </h2>
            <div>
              Our suite of digital tools ensures that no detail is missed, from
              mobile recordings in the field to low-light OR environments,
              capturing high-quality data wherever needed.
            </div>
            <div style={{ marginTop: "1em" }}>
              Get the{" "}
              <b style={{ color: "#FFA100" }}>best quality recordings</b> with
              digitalization tool that fits to your environment, workflow and
              facilities.
            </div>
          </Grid>
        </Grid>

        <Grid container item style={block} marginTop={2} marginBottom={2}>
          <Grid
            item
            xs={10}
            sm={5}
            style={text}
            order={{ xs: 2, sm: 1 }}
            marginTop={{ xs: 2, sm: 0 }}
          >
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>
            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              <b style={{ color: "#FFA100" }}>Simplify </b>the setup
            </h2>
            <div>
              Experience the simplicity of our plug-and-play solutions, designed
              to minimize setup time. Effortlessly install it yourself—no
              engineer required.
            </div>
            <div style={{ marginTop: "1em" }}>
              Your time should be spent on what{" "}
              <b style={{ color: "#FFA100" }}>really matters.</b>
            </div>
          </Grid>
          <Grid item xs={10} sm={5} order={{ xs: 1, sm: 2 }}>
            <StaticImage
              src="../../images/gifs/2.png"
              alt=""
              loading="lazy"
              placeholder="blurred"
              className="rule_img"
              quality={90}
              formats={["auto", "webp", "avif"]}
            />{" "}
          </Grid>
        </Grid>

        <Grid container item style={block} marginTop={2} marginBottom={2}>
          <Grid item xs={10} sm={5}>
            <StaticImage
              src="../../images/DrAhmed.png"
              alt="Dr Ahmed mentoring Dr Ticiana de Francesco"
              loading="lazy"
              placeholder="blurred"
              className="rule_img"
              quality={90}
              formats={["auto", "webp", "avif"]}
            />
            <div style={{ textAlign: "center", fontSize: "14px" }}>
              Dr. Ike Ahmed, in Canada, mentoring Dr. Ticiana de Francesco, in
              Brazil.
            </div>
          </Grid>
          <Grid item xs={10} sm={5} style={text} marginTop={{ xs: 2, sm: 0 }}>
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>

            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              <b style={{ color: "#FFA100" }}>Share</b> your expertise with the
              world{" "}
            </h2>
            <div>
              Get <b style={{ color: "#FFA100" }}>instant access</b> of your
              procedures{" "}
              <b style={{ color: "#FFA100" }}>whenever you need them</b>. Review
              as many times as it is necessary and track your progress. Or your
              students progress - at a distance if that's the case. That's the
              medical technology you need.
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Rules;
